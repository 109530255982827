import React from "react";
import { Datagrid, List, TextField, ShowButton, TextInput } from "react-admin";
import CategoryParentDisplayField from "../../components/Fields/CategoryParentDisplayField";

const categoriesFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
];

const CategoriesList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "name", order: "ASC" }}
      filters={categoriesFilters}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="slug" />
        <CategoryParentDisplayField source="parent_id" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default CategoriesList;
