import { Create, SimpleForm, TextInput } from "react-admin";
import GalleryImageInput from "../../components/Inputs/GalleryImageInput";

const BrandCreate: React.FC<any> = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="url" />

      <GalleryImageInput
        source="logo_id"
        single
        label="Logo"
        uploadType="brand_logo"
      ></GalleryImageInput>
    </SimpleForm>
  </Create>
);

export default BrandCreate;
