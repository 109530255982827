import BrandsEdit from "./Edit";
import BrandsShow from "./Show";
import BrandsList from "./List";
import BrandsCreate from "./Create";
import { SiBrandfolder } from "react-icons/si";

const brandsResource = {
  icon: SiBrandfolder,
  show: BrandsShow,
  edit: BrandsEdit,
  list: BrandsList,
  create: BrandsCreate,
};

export default brandsResource;
