import React from "react";
import {DateField, Datagrid, List, ShowButton, TextField, TextInput} from "react-admin";

const supportRequestFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
];

const SupportRequestsList: React.FC<any> = (props) => {
  return (
    <List
      title="Support Requests"
      {...props}
      bulkActionButtons={false}
      sort={{ field: "created_at", order: "DESC" }}
      filters={supportRequestFilters}
    >
      <Datagrid>
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="subject" />
        <TextField source="email_address" />
        <DateField source="created_at" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default SupportRequestsList;
