import {
    Create,
    required,
    number,
    minValue,
    SimpleForm,
    TextInput,
} from "react-admin";
import React from "react";

const IndustryCreate: React.FC<any> = (props) => (
    <Create {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <TextInput source="sort_order" validate={[required(), number(), minValue(0)]}/>
            <TextInput source="description" multiline validate={required()}/>
        </SimpleForm>
    </Create>
);

export default IndustryCreate;
