import List from "./List";
import Show from "./Show";
// import { MdPeopleOutline } from "react-icons/md";
import { IoBusinessOutline } from "react-icons/io5";

const externalSuppliersResource = {
  icon: IoBusinessOutline,
  show: Show,
  list: List,
};

export default externalSuppliersResource;
