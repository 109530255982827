import CategoriesList from "./List";
import CategoriesShow from "./Show";
import Create from "./Create";
import Edit from "./Edit";

import { MdCategory } from "react-icons/md";

const categoriesResource = {
  icon: MdCategory,
  show: CategoriesShow,
  list: CategoriesList,
  create: Create,
  edit: Edit,
};

export default categoriesResource;
