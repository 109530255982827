import React, { useState } from "react";
import {
  Button,
  ImageField,
  ImageInput,
  useCreate,
  useGetList,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { FormProvider, useForm } from "react-hook-form";

const useStyles = makeStyles({
  imagesList: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
  },
  imageList: {
    width: "20%",
    height: "97px",
    padding: "14px",
    margin: "10px",
    borderRadius: 6,
    objectFit: "contain",
    backgroundColor: "rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
      cursor: "pointer",
    },
  },
  title: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 400,
    lineHeight: 1,
  },
  addImage: {
    height: 100,
    width: 100,
    border: "2px dashed lightgray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "gray",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: 5,
    margin: 10,
    // marginLeft: 20,
    "&:hover": {
      color: "black",
      border: "2px dashed black",
    },
  },
  search: {
    position: "relative",
    borderRadius: 3,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    marginLeft: 0,
    marginBottom: 10,
    width: "100%",
  },
  searchIcon: {
    // padding: 4,
    paddingLeft: 4,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    marginRight: 10,

    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    paddingLeft: 10,
  },
  inputInput: {
    padding: 10,
    paddingLeft: `20px`,
    width: "100%",
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CreateButton({ onChange, uploadType = "product_image" }: any) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [tabIndex, setTabindex] = useState(0);
  const [fileSearch, setFileSearch] = useState("");

  const {
    data: images,
    isLoading: filesLoading,
    refetch,
  } = useGetList("files", {
    pagination: {
      perPage: 1000,
      page: 1,
    },
    sort: {
      field: "created_at",
      order: "DESC",
    },
    filter: {
      search: fileSearch,
    },
  });

  const handleClick = () => {
    refetch();
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const methods = useForm<any>();

  const uploadedImage = methods.watch("image");

  const [create] = useCreate();

  const onSubmit = async (values: any) => {
    let formData: any = new FormData();

    formData.append("file", values.image.rawFile);
    formData.append("name", "test product name");

    await create(
      `files/${uploadType}`,
      { data: formData },
      {
        onSuccess: ({ data }: any) => {
          console.log(data);
        },
        onError: (e: any) => {
          console.log(e);
        },
        onSettled(data) {
          methods.reset();
          onChange(data.id);
          handleCloseClick();
        },
      }
    );
  };

  return (
    <>
      <button type="button" onClick={handleClick} className={classes.addImage}>
        Choose Image
      </button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Upload / Choose Image"
      >
        <DialogTitle>Upload / Choose Media</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabIndex}
            onChange={(e, nv) => setTabindex(nv)}
            aria-label="simple tabs example"
          >
            <Tab label="Choose Image" />
            <Tab label="Upload File" />
          </Tabs>

          <TabPanel value={tabIndex} index={0}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={fileSearch}
                onChange={(e) => setFileSearch(e?.target?.value ?? "")}
                placeholder="Search existing images"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            <div className={classes.imagesList}>
              {images &&
                images.map((image: any) => (
                  <img
                    key={image.id}
                    src={image.url}
                    alt={image.name}
                    className={classes.imageList}
                    onClick={() => {
                      onChange(image.id);
                      handleCloseClick();
                    }}
                  />
                ))}
            </div>

            {filesLoading && <p>Loading...</p>}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <ImageInput source="image" label="Image" accept="image/*">
                  <ImageField source="image" />
                </ImageInput>

                {uploadedImage && (
                  <img
                    src={uploadedImage?.src}
                    alt={"uploaded"}
                    className={classes.imageList}
                    // onClick={() => {
                    //   onChange(image.id);
                    //   handleCloseClick();
                    // }}
                  />
                )}
                <div>
                  {uploadedImage && <button type="submit">Save</button>}
                </div>
              </form>
            </FormProvider>
          </TabPanel>
        </DialogContent>

        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateButton;
