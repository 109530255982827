import EolItemsList from "./List";
import EolItemsShow from "./Show";
import { GrTask } from "react-icons/gr";

const eolItemsResource = {
  icon: GrTask,
  show: EolItemsShow,
  list: EolItemsList,
};

export default eolItemsResource;
