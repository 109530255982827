import ManufacturersList from "./List";
import ManufacturersShow from "./Show";
import { MdPrecisionManufacturing } from "react-icons/md";

const manufacturersResource = {
  list: ManufacturersList,
  show: ManufacturersShow,
  icon: MdPrecisionManufacturing,
};

export default manufacturersResource;
