import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Loading, Title, useDataProvider } from "react-admin";
import { useParams } from "react-router-dom";

const ManufacturerIndustryShow: React.FC<any> = () => {
  const { manufacturerId, industryId } =
    useParams<{ manufacturerId: string; industryId: string }>();

  const dataProvider = useDataProvider();
  const [manufacturer, setManufacturer] = useState<any>();
  const [industry, setIndustry] = useState<any>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getOne("manufacturers", { id: manufacturerId })
      .then(({ data }) => {
        setManufacturer(data);

        const industryData = data.industries.find(
          (ind: any) => ind.id === parseInt(industryId)
        );
        if (!industryData) throw Error();
        setIndustry(industryData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [manufacturerId, industryId]); // eslint-disable-line

  if (loading) return <Loading />;
  if (error) return null;
  if (!manufacturer) return null;

  const approveSubmission = async () => {};

  const renderFieldForType = (type: string, key: string) => {
    try {
      const value = industry.certifications[key];

      switch (type) {
        case "textarea":
        case "text":
        case "select":
          return <p>{value}</p>;

        case "multiselect":
          return <p>{value.map((ids: any) => ids.label).join(", ")}</p>;
        case "files":
          return (
            <div>
              {value.map((file: any) => (
                <a href={file.url} key={file.path} download>
                  Download
                </a>
              ))}
            </div>
          );
        default:
          break;
      }
    } catch (error) {
      return <div></div>;
    }
  };

  return (
    <Card>
      <Title title="Manufacturer industry details" />

      <CardContent>
        {industry.registration_settings.map((sett: any) => (
          <div
            key={sett.id}
            style={{
              border: "1px solid lightgray",
              marginBottom: "15px",
              borderRadius: "6px",
              padding: "5px 10px",
            }}
          >
            <h4>{sett.label}:</h4>
            {renderFieldForType(sett.type, sett.id)}
          </div>
        ))}

        {!industry.verified && (
          <Button
            title=""
            onClick={() => approveSubmission()}
            variant="contained"
            color="secondary"
          >
            Verify Submission
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ManufacturerIndustryShow;
