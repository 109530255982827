import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import ProductCategoryDataInput from "../../components/Inputs/ProductCategoryDataInput";
import GalleryImageInput from "../../components/Inputs/GalleryImageInput";

const ProductEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProductsEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props} undoable={false} mutationMode="pessimistic">
      <TabbedForm toolbar={<ProductEditToolbar />}>
        <FormTab label="Product Details">
          <TextInput source="name" validate={required()} />
          <TextInput source="make" />
          <TextInput source="model" />
          <NumberInput source="guide_price" label="Guide Price (GBP)" min={0} />
          <ReferenceInput label="Brand" source="brand_id" reference="brands">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="short_description" />
          <RichTextInput source="description" />
          <BooleanInput source="verified" />
        </FormTab>
        <FormTab label="Images">
          <GalleryImageInput
            source="thumbnail_id"
            single
            label="Thumbnail"
          ></GalleryImageInput>

          <GalleryImageInput
            source="image_ids"
            label="Product Images"
          ></GalleryImageInput>
        </FormTab>
        <FormTab label="Categories">
          <ReferenceArrayInput
            source="category_ids"
            reference="categories"
            label="Categories"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Category Info">
          {/* <div>{JSON.stringify(record)}</div> */}
          <FormDataConsumer>
            {({ formData }) => {
              return <ProductCategoryDataInput productId={formData.id} />;
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ProductsEdit;
