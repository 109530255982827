import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import {
  Labeled,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";

const useStyles: any = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "transparent",
    marginBottom: 10,
    marginTop: 10,
  },
  imageList: {
    maxWidth: 150,
    maxHeight: 150,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 6,
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  title: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 400,
    lineHeight: 1,
  },
});

const ProductsIcecatImport: React.FC<any> = (props) => {
  const [products, setProducts] = useState<undefined | any[]>(undefined);
  const [icecatId, setIcecatId] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [importing, setImporting] = useState<any>([]);
  const [productImported, setProductImported] = useState<any>([]);
  const [error, setError] = useState(false);
  const [importError, setImportError] = useState<any>([]);

  const classes = useStyles();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIcecatId(event.target.value);
  };

  const handleRefreshClick = () => {
    setProducts(undefined);
  };

  const notify = useNotify();
  const IcecatToolbar = () => {
    const onSubmit = async () => {
      setIsLoading(true);
      setError(false)
      try {
        const { data } = await axios.get("/icecat-product", {
          params: {
            id: icecatId,
          },
        });
        setProducts(data);
        setIsLoading(false);
      } catch (error: any) {
        notify(error.response.data.message, {
          type: "error",
        });
        setIsLoading(false);
        setError(true); //handle error
      }
    };

    return (
      <Toolbar>
        <SaveButton
          label="Get Icecat product"
          disabled={isLoading}
          type="button"
          onClick={onSubmit}
        />
      </Toolbar>
    );
  };

  return (
    <div>
      {products ? (
        <div>
          <table>
            <tr>
              <th>Thumbnail</th>
              <th>Name</th>
              <th>Make</th>
              <th>Short Description</th>
              <th>Brand</th>
              <th>Category</th>
              <th></th>
            </tr>
            {products.map((product, key) => {
              const ImportProduct = async (key: number, variantId?: number) => {
                setImporting([...importing, key]);
                try {
                  await axios.post("import-icecat-product", {
                    icecatId: product.icecat_id,
                    variantId: product.variant_id,
                  });
                  setProductImported((productImported: any) => [
                    ...productImported,
                    key,
                  ]);
                } catch (error: any) {
                  setImportError([...importError, key]);
                  notify(error.response.data.message, {
                    type: "error",
                  });
                }
              };
              return (
                <tr key={key}>
                  <td>
                    <img
                      src={product.thumbnail_url}
                      alt={product.name}
                      className={classes.imageList}
                    />
                  </td>
                  <td className={classes.title}>{product.name}</td>
                  <td className={classes.title}>{product.make}</td>
                  <td className={classes.title}>{product.short_description}</td>
                  <td className={classes.title}>{product.brand}</td>
                  <td className={classes.title}>{product.category}</td>
                  <td>
                    {importError.includes(key) && (
                      <div style={{ color: "red" }}>
                        Error importing try again
                      </div>
                    )}
                    {productImported.includes(key) ? (
                      <div style={{ color: "green" }}>Successfuly imported</div>
                    ) : (
                      <button
                        onClick={() => ImportProduct(key, product.variant_id)}
                      >
                        {importing.includes(key)
                          ? "Importing..."
                          : "Import product"}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>

          {/* refresh button */}
          <div>
            <button onClick={handleRefreshClick}>Input new icecat id</button>
          </div>
        </div>
      ) : (
        <SimpleForm toolbar={<IcecatToolbar />}>
          {isLoading && <div>Fetching products...</div>}
          {error && <div style={{ color: "red" }}>Error fetching products, please try again or contact support</div>}
          <Labeled label="Get an Icecat product id and input">
            <TextInput
              source="Product Icecat-id"
              type="text"
              onChange={handleInput}
            />
          </Labeled>
        </SimpleForm>
      )}
    </div>
  );
};

export default ProductsIcecatImport;


//ProductsProductsIcecatImport