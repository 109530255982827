import React from "react";
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    useShowController,
} from "react-admin";

const EolItemShow: React.FC<any> = (props) => {
    const {record} = useShowController(props);
    if (!record) return null;

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="details">
                    <TextField source="serial_number" />
                    <TextField source="price" />
                    <TextField source="status" />
                    <TextField source="product_details" />
                    <TextField label="manufacturer" source="user.name" />
                </Tab>

                <Tab label="product">
                    <TextField source="product.name" />
                    <TextField source="product.make" />
                    <TextField source="product.model" />
                    <TextField source="product_details" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default EolItemShow;
