import { FormDataConsumer, ReferenceInput, SelectInput } from "react-admin";

const IndustryCategorySelectInput = (props: any) => {
  return (
    <span>
      <SelectInput
        source="parent_type"
        choices={[
          { id: `industry`, name: "Industry" },
          { id: "category", name: "Category" },
        ]}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.parent_type && (
            <>
              {formData.parent_type === "category" && (
                <ReferenceInput
                  source="parent_id"
                  reference="categories"
                  label="Category"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )}
              {formData.parent_type === `industry` && (
                <ReferenceInput
                  source="parent_id"
                  reference="industries"
                  label="Industry"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )}
            </>
          )
        }
      </FormDataConsumer>
    </span>
  );
};
export default IndustryCategorySelectInput;
