import { useEffect, useState } from "react";
import {
  FileField,
  FileInput,
  RefreshButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
} from "react-admin";
import CSVFileValidator from "csv-file-validator";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProductsImport: React.FC<any> = (props) => {
  const [csvContent, setCsvContent] = useState<any>();
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const [filtererdData, setFiltererdData] = useState([]);
  const [apiError, setApiError] = useState<any>([]);
  const [errorMessages, setErrorMessages] = useState<any>();

  const rowsArray: any[] = [];
  const valuesArray: any[] = [];

  useEffect(() => {
    if (!csvContent) return;

    csvContent.map((d: any) => {
      rowsArray.push(Object.keys(d));
      valuesArray.push(Object.values(d));
    });

    setTableRows(rowsArray[0]);

    setValues(valuesArray);
  }, [csvContent, apiError]);

  useEffect(() => {
    console.log("changes effect");
    filterData(validationErrors, csvContent);
  }, [validationErrors, csvContent, filtererdData]);

  const handleCSVInput = async (file: any) => {
    CSVFileValidator(file, CSVConfig)
      .then((csvData) => {
        setCsvContent(csvData.data);

        setValidationErrors(csvData.inValidData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let CSVConfig = {
    headers: [
      {
        name: "Name",
        inputName: "name",
        required: true,
      },
      {
        name: "Make",
        inputName: "make",
        required: true,
      },
      {
        name: "Model",
        inputName: "model",
        required: true,
      },
      {
        name: "Short Description",
        inputName: "short_description",
        required: true,
      },
      {
        name: "Description",
        inputName: "description",
        required: true,
      },
      {
        name: "Guide Price",
        inputName: "guide_price",
        required: true,
        validate: function (guide_price: any) {
          return !isNaN(guide_price);
        },
        validateError: function (
          headerName: string,
          rowNumber: number,
          columnNumber: number
        ) {
          return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
        },
      },
      {
        name: "Brand",
        inputName: "brand",
        required: false,
      },
      {
        name: "Categories",
        inputName: "categories",
        required: false,
      },
    ],
  };

  const rowValidation = (val: any[], index: any): React.ReactNode => {
    if (val.length !== 8) {
      return (
        <div style={{ color: "red" }}>
          This is an invalid row. A row must have only name, make, model, short
          description, description, guide price, brand.
        </div>
      );
    }

    return (
      <tr key={index}>
        {val.map((val, i) => {
          return (
            <td
              key={i}
              style={{
                color: cellValidation(val, i) === true ? "black" : "red",
                margin: "0.25rem",
                background: `${apiError.includes(index) ? "#FFDCDC" : "white"}`,
              }}
            >
              {val.length === 0 ? (
                <span style={{ color: "red" }}>Missing value</span>
              ) : (
                <span>{val}</span>
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  const cellValidation = (val: any, index: number) => {
    const parsedValue = parseFloat(val);
    switch (index) {
      case 0:
        return isNaN(parsedValue) ? true : false;
      case 1:
        return isNaN(parsedValue) ? true : false;
      case 2:
        return isNaN(parsedValue) ? true : false;
      case 3:
        return isNaN(parsedValue) ? true : false;
      case 4:
        return isNaN(parsedValue) ? true : false;
      case 5:
        return !isNaN(parsedValue) ? true : false;
      case 6:
        return isNaN(parsedValue) ? true : false;
      case 7:
        return typeof val === "string";

      default:
        return false;
    }
  };

  const ImportToolbar = () => {
    const notify = useNotify();

    const onSubmit = async (e: any) => {
      setCsvContent(filtererdData);
      try {
        await axios.post("/products/import", {
          products: csvContent,
        });
        notify("Products CSV submitted", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        navigate("/products");
      } catch (error: any) {
        notify(error.response.data.message, {
          type: "error",
        });
        handleErrors(error.response.data.errors);
      }
    };

    const handleErrors = (returnError: any) => {
      const productError = Object.keys(returnError);
      const productErrorMessage = Object.values(returnError);
      let keyArray: number[] = [];
      let errorObject: any = {};

      productError.map((error: any) => {
        const key = parseInt(error.split(".")[1]);

        const errorMessageKey = productError.indexOf(error);
        const errorMessage = productErrorMessage[errorMessageKey];
        keyArray.push(key);
        errorObject[key] = errorMessage;
      });

      setApiError([...keyArray]);
      setErrorMessages(errorObject);
    };

    let navigate = useNavigate();
    return (
      <Toolbar>
        <SaveButton
          label="Save CSV"
          disabled={filtererdData.length === 0}
          type="button"
          onClick={onSubmit}
        />
        {csvContent && (
          <RefreshButton
            label="Try again"
            type="button"
            onClick={() => {
              setCsvContent(null);
              setValidationErrors([]);
              setFiltererdData([]);
            }}
            style={{
              marginLeft: "1.75rem",
            }}
          />
        )}
      </Toolbar>
    );
  };

  const filterData = (validationErrors: any[], data: any) => {
    if (!data) return;
    if (validationErrors?.length !== 0) {
      const adjustedIndexes: number[] = [];

      for (let i = 0; i < validationErrors?.length; i++) {
        const adjustedIndex = validationErrors[i].rowIndex - 2;

        if (!adjustedIndexes.includes(adjustedIndex)) {
          adjustedIndexes.push(adjustedIndex);
        }
      }

      for (let i = adjustedIndexes?.length - 1; i >= 0; i--) {
        const index = adjustedIndexes[i];
        if (index >= 0 && index < data?.length) {
          data.splice(index, 1);
        }
      }
    }

    setCsvContent(data);
    setFiltererdData(data);
  };

  return (
    <>
      {validationErrors.length > 0 && (
        <div style={{ color: "red" }}>
          This csv file has invalid data and that data will be filtered out
          before submission
        </div>
      )}
      <SimpleForm toolbar={<ImportToolbar />}>
        {csvContent ? (
          <div>
            <table>
              <thead>
                <tr>
                  {tableRows.map((rows, index) => {
                    return <th key={index}>{rows}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {values.map((value: any[], index: any) => {
                  return rowValidation(value, index);
                })}
              </tbody>
            </table>
            <div>
              {apiError.length > 0 &&
                apiError.map((key: number, value: number) => {
                  return (
                    <p key={key} style={{ color: "red", lineHeight: "1px" }}>
                      At row {key + 1} <span>{errorMessages[key]}</span>
                    </p>
                  );
                })}
            </div>
          </div>
        ) : (
          <FileInput
            source="Product CSV"
            type="file"
            placeholder={<p>Drop your CSV file here</p>}
            onChange={handleCSVInput}
            accept=".csv"
          >
            <FileField source="src" title="title" />
          </FileInput>
        )}
      </SimpleForm>
    </>
  );
};

export default ProductsImport;
