import React from "react";
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  useShowController,
  ReferenceManyField,
  ShowButton,
  TopToolbar,
  EditButton, ArrayField, BooleanField,
} from "react-admin";
import PreviewButton from "../../components/Buttons/PreviewButton";
import CategoryParentDisplayField from "../../components/Fields/CategoryParentDisplayField";

const ShowCategoryActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <PreviewButton resource={resource} record={data} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const CategoryShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} actions={<ShowCategoryActions />}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="description" />

          <CategoryParentDisplayField
            source="parent_id"
            title="Parent Category / Industry"
          />
        </Tab>

        <Tab label="categories" path="categories">
          <ReferenceManyField
            reference="categories"
            target="category"
            addLabel={false}
          >
            <Datagrid hasBulkActions={false}>
              <TextField source="name" />
              <TextField source="slug" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Product Details Fields">
          <ArrayField source="product_detail_fields">
            <Datagrid>
              <TextField source="label" />
              <TextField source="type" />
              <BooleanField source="required" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CategoryShow;
