import React, { useEffect, useMemo } from "react";
import { useGetMany, useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles: any = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "transparent",
    marginBottom: 10,
    marginTop: 10,
  },
  imageList: {
    maxWidth: (props: any) => props.maxS,
    maxHeight: (props: any) => props.maxS,
    marginLeft: (props) => (props.hasGap ? 10 : 0),
    marginRight: (props) => (props.hasGap ? 10 : 0),
    borderRadius: 6,
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  title: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 400,
    lineHeight: 1,
  },
});

const GalleryImageField: React.FC<any> = (props) => {
  const { source } = props;

  const record = useRecordContext(props);

  const classes = useStyles({
    maxS: props.maxSize ?? 220,
    hasGap: !props.single,
  });

  const idsOrId = useMemo(() => {
    if (props.overWriteValue !== undefined)
      return props.single ? [props.overWriteValue] : props.overWriteValue;

    return props.single ? [record[source]] : record[source];
  }, [props.overWriteValue, props.single, record, source]);

  const {
    data: images,
    isLoading,
    error,
    refetch,
  } = useGetMany(
    "files",
    { ids: idsOrId.filter(Boolean) ?? [] },
    {
      enabled: idsOrId.filter(Boolean).length > 0,
    }
  );

  useEffect(() => {
    refetch();
  }, [props.overWriteValue, refetch]);

  if (idsOrId === null || (Array.isArray(idsOrId) && idsOrId.length === 0))
    return null;

  if (isLoading) return null;
  if (error) return null;
  if (!images) return null;

  return (
    <>
      {props.title && <h5 className={classes.title}>{props.title}</h5>}
      <div className={classes.root}>
        {images.map((image: any) => (
          <img
            key={image.id}
            src={image.url}
            alt={image.name}
            className={classes.imageList}
            style={{ maxWidth: props.maxSize ?? 220 }}
          />
        ))}
      </div>
    </>
  );
};

export default GalleryImageField;
