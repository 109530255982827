import React from "react";
import {
  Show,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  DateField,
  ReferenceManyField,
  Datagrid,
  useShowController,
  ArrayField,
  ShowButton,
  ImageField,
  TopToolbar,
  useUpdate,
  useRecordContext,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PreviewButton from "../../components/Buttons/PreviewButton";

const useImageFieldStyles = makeStyles((theme) => ({
  image: {
    width: 40,
    height: 40,
  },
}));

const ManufacturerShowActions = ({ resource }: any) => {
  const record = useRecordContext();
  const [verify, { isLoading }] = useUpdate("manufacturers", {
    id: record.id,
    data: { verified: true },
  });

  return (
    <TopToolbar>
      <PreviewButton resource={resource} record={record} />

      {!record.verified && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => verify()}
          disabled={isLoading}
        >
          Verify Manufacturer
        </Button>
      )}
    </TopToolbar>
  );
};

const ManufacturerShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  const imageFieldClasses = useImageFieldStyles();

  if (!record) return null;

  //
  return (
    <Show {...props} actions={<ManufacturerShowActions />}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <TextField source="company_number" />
          <DateField label="Registered At" source="created_at" />
          <BooleanField source="verified" />
          <TextField source="summary" />
        </Tab>
        {record.verified === false && (
          <Tab label="registration" path="registration">
            <TextField source="name" />
            <TextField source="company_number" />
            <DateField label="Registered At" source="created_at" />
          </Tab>
        )}

        <Tab label="users" path="users">
          <ReferenceManyField
            reference="users"
            target="manufacturer"
            label={undefined}
          >
            <Datagrid bulkActionButtons={<></>}>
              <TextField source="name" />
              <TextField source="email" />
              <TextField source="phone" />
              <TextField source="role" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="products" path="product-items">
          <ReferenceManyField
            reference="product-items"
            target="manufacturer"
            label={undefined}
          >
            <Datagrid bulkActionButtons={<></>}>
              <TextField source="id" />
              <ImageField
                className={imageFieldClasses.image}
                source="thumbnail"
                title=""
              />
              <TextField source="product_name" />
              <TextField source="status" />
              <TextField source="serial_number" />
              <TextField source="blockchain_id" />
              <TextField source="price" />
              <TextField source="order_id" />
              <TextField source="delivery_price" />
              <TextField source="est_lead_time" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="reviews" path="reviews">
          <TextField source="rating" />

          <ReferenceManyField
            reference="reviews"
            target="manufacturer"
            // addLabel={false}
            label={undefined}
          >
            <Datagrid bulkActionButtons={<></>}>
              <TextField source="title" />
              <TextField source="stars" />
              <TextField source="comment" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="industries" path="industries">
          <ArrayField source="industries" resource="industries">
            <Datagrid>
              <TextField source="name" />
              <BooleanField source="verified" />
              <ViewManufacturerIndustryAction manufacturer={record} />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ViewManufacturerIndustryAction = ({ record, manufacturer }: any) => {
  return (
    <ShowButton
      // basePath={`/manufacturers/${manufacturer.id}/industries`}
      record={record}
    />
  );
};
export default ManufacturerShow;
