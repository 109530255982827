import React from "react";
import {Datagrid, List, TextField, ShowButton, TextInput } from "react-admin";

const eolItemsFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
];

const EolItemsList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "serial_number", order: "ASC" }}
      filters={eolItemsFilters}
    >
      <Datagrid>
        <TextField source="serial_number" />
        <TextField source="price" />
        <TextField source="status" />
        <TextField source="product_details" />
        <TextField label="manufacturer" source="user.name" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default EolItemsList;
