import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  BooleanField,
  ReferenceField,
  TextInput,
  BooleanInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  Button,
} from "react-admin";
import GalleryImageField from "../../components/Fields/GalleryImageField";
import { MdFileUpload } from "react-icons/md";

const productsFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
  <BooleanInput label="Only show verified" source="onlyVerified" alwaysOn />,
];

const ListActions = () => {
  let navigate = useNavigate();

  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      <Button
        to="products/import"
        onClick={() => {
          navigate("/products/import");
        }}
        label="Import CSV"
      >
        <MdFileUpload />
      </Button>
      <Button
        to="products/icecat-import"
        onClick={() => {
          navigate("/products/icecat-import");
        }}
        label="Import Icecat Product"
      >
        <MdFileUpload />
      </Button>
    </TopToolbar>
  );
};
const ProductsList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ListActions />}
      sort={{ field: "name", order: "ASC" }}
      filters={productsFilters}
    >
      <Datagrid>
        <GalleryImageField
          source="thumbnail_id"
          single
          maxSize={50}
          sortable={false}
        />
        <TextField source="name" />
        <TextField source="stock_level" />
        <ReferenceField
          label="Brand"
          source="brand.id"
          reference="brands"
          sortable={false}
          link="show"
        >
          <TextField source="name" label="Brand" />
        </ReferenceField>
        <TextField source="make" />
        <TextField source="model" />
        <BooleanField source="verified" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ProductsList;
