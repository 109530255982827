import { ReferenceField, TextField, useRecordContext } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const useStyles: any = makeStyles({
  title: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 400,
    lineHeight: 1,
  },
});

const CategoryParentDisplayField: React.FC<any> = (props) => {
  const record = useRecordContext(props);

  const classes = useStyles();
  return (
    <>
      {props.title && <h5 className={classes.title}>{props.title}</h5>}
      <ReferenceField
        label="Parent"
        source="parent_id"
        reference={
          record.parent_type === "category" ? "categories" : "industries"
        }
        sortable={false}
        link="show"
      >
        <TextField source="name" label="Category / Industry" />
      </ReferenceField>
      <div
        style={{
          display: "block",
          backgroundColor: "transparent",
          height: 20,
          width: "100%",
        }}
      />
    </>
  );
};

export default CategoryParentDisplayField;
