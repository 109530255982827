import React from "react";
import {
    Show,
    Datagrid,
    DateField,
    ReferenceField,
    ReferenceManyField,
    TextField,
    TabbedShowLayout,
    Tab,
    useShowController,
} from "react-admin";

const OrderShow: React.FC<any> = (props) => {
    const {record} = useShowController(props);
    if (!record) return null;

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="details">
                    <TextField label="User Id" source="user_id"/>
                    <ReferenceField
                        source="user_id"
                        reference="users"
                        sortable={false}
                        link="show"
                    >
                        <TextField source="name"/>
                    </ReferenceField>

                    <TextField source="order_number"/>
                    <ReferenceField
                        label="Manufacturer"
                        source="manufacturer_id"
                        reference="manufacturers"
                        sortable={false}
                        link="show"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="amount"/>
                    <TextField source="shipping_cost"/>
                    <TextField source="status"/>
                    <DateField source="shipped_at"/>
                    <DateField source="completed_at"/>
                </Tab>

                <Tab label="products" path="product-items">
                    <ReferenceManyField
                        reference="product-items"
                        target="order"
                        addLabel={false}
                    >
                        <Datagrid hasBulkActions={false}>
                            <TextField source="id" />
                            <TextField source="product_name" />
                            <TextField source="status" />
                            <TextField source="serial_number" />
                            <TextField source="blockchain_id" />
                            <TextField source="price" />
                            <TextField source="order_id" />
                            <TextField source="delivery_price" />
                            <TextField source="est_lead_time" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                <Tab label="payment">
                    <TextField label="Stripe Id" source="stripe_id"/>
                    <TextField source="payment_method"/>
                </Tab>

                <Tab label="address">
                    <TextField label="Building Number" source="address.building_no"/>
                    <TextField label="Street" source="address.street"/>
                    <TextField label="Town/City" source="address.town_city"/>
                    <TextField label="Country" source="address.country"/>
                    <TextField label="Postcode" source="address.postcode"/>
                    <TextField label="Phone" source="address.contact_number"/>
                    <TextField label="Delivery Notes" source="address.delivery_notes"/>
                </Tab>

            </TabbedShowLayout>
        </Show>
    );
};

export default OrderShow;
