import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab,
  ReferenceArrayInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
} from "react-admin";

const IndustryEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const IndustriesEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props} undoable={false} mutationMode="pessimistic">
      <TabbedForm toolbar={<IndustryEditToolbar />}>
        <FormTab label="Industry Details">
          <TextInput source="name" validate={required()} />
          <TextInput
            source="description"
            multiline
            validate={required()}
            minRows={4}
          />
        </FormTab>
        <FormTab label="Registration Settings">
          <ArrayInput source="registration_settings">
            <SimpleFormIterator>
              <TextInput label="Field Name" source="label" />
              <SelectInput
                label="Field Type"
                source="type"
                choices={[
                  {
                    id: "text",
                    name: "Text",
                  },
                  {
                    id: "textarea",
                    name: "Text Area",
                  },
                  {
                    id: "file",
                    name: "File",
                  },
                  {
                    id: "files",
                    name: "Files",
                  },
                  {
                    id: "checkbox",
                    name: "Checkbox",
                  },
                  {
                    id: "select",
                    name: "Select",
                  },
                  {
                    id: "multiselect",
                    name: "Multiselect",
                  },
                ]}
              />
              <BooleanInput source="required" label="Field Required" />

              <FormDataConsumer>
                {({ scopedFormData, getSource, ...rest }: any) =>
                  scopedFormData?.type && (
                    <>
                      {["select", "multiselect"].includes(
                        scopedFormData.type
                      ) && (
                        <ArrayInput
                          source={getSource("options")}
                          label="Select options"
                        >
                          <SimpleFormIterator>
                            <TextInput source="" label="Field Option" />
                          </SimpleFormIterator>
                        </ArrayInput>
                      )}
                    </>
                  )
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Brands">
          <p>
            Brands selected here will be associated with the industry and will
            be shown in menu when industry is hovered.
          </p>
          <ReferenceArrayInput
            source="brand_ids"
            reference="brands"
            label="Brands"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default IndustriesEdit;
