import React from "react";
import {
  Edit,
  ImageField,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

const BrandEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const FilesEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<BrandEditToolbar />}>
        <ImageField source="url" label="Preview" />
        <TextInput
          source="name"
          validate={required()}
          label="Image Title / Alt Attribute"
        />
      </SimpleForm>
    </Edit>
  );
};

export default FilesEdit;
