import { Create, required, SimpleForm, TextInput } from "react-admin";
import React from "react";
import IndustryCategorySelectInput from "../../components/Inputs/IndustryCategorySelectInput";

const CategoryCreate: React.FC<any> = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" multiline validate={required()} />
      <IndustryCategorySelectInput />
    </SimpleForm>
  </Create>
);

export default CategoryCreate;
