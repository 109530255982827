
import IndustriesCreate from "./Create";
import IndustriesEdit from "./Edit";
import IndustriesList from "./List";
import IndustriesShow from "./Show";
import { MdOutlineCategory } from "react-icons/md";

const industriesResource = {
  create: IndustriesCreate,
  edit: IndustriesEdit,
  list: IndustriesList,
  show: IndustriesShow,
  icon: MdOutlineCategory,
};

export default industriesResource;
