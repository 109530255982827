import { Amplify } from "@aws-amplify/core";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { buildAuthProvider } from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";
import awsExports from "./aws-exports";
import brands from "./resources/brands";
import categories from "./resources/categories";
import eolItems from "./resources/eolItems";
import industries from "./resources/industries";
import manufacturers from "./resources/manufacturers";
import orders from "./resources/orders";
import products from "./resources/products";
import supportRequests from "./resources/supportRequests";
import users from "./resources/users";
import admins from "./resources/admins";

import files from "./resources/files";
import ManufacturerIndustryShow from "./resources/manufacturers/ManufacturerIndustryShow";
import { Route } from "react-router-dom";
import ProductsImport from "./resources/products/Import";
import ProductsIcecatImport from "./resources/products/icecat";
import externalSuppliers from "./resources/externalSuppliers";

Amplify.configure(awsExports);

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={buildAuthProvider({ authGroups: ["admin"] })}
    >
      <CustomRoutes>
        <Route
          path="/manufacturers/:manufacturerId/industries/:industryId/show"
          element={<ManufacturerIndustryShow />}
        />
      </CustomRoutes>

      <Resource name="admins" {...admins} />

      <Resource name="brands" {...brands} />
      <Resource name="categories" {...categories} />
      <Resource
        name="eol-items"
        options={{ label: "Eol Items" }}
        {...eolItems}
      />
      <Resource name="files" options={{ label: "Gallery" }} {...files} />

      <Resource name="industries" {...industries} />
      <Resource name="industry-categories" />

      <Resource name="manufacturers" {...manufacturers} />
      <Resource name="orders" {...orders} />
      <Resource name="product-items" />
      <Resource name="products" {...products} />
      <CustomRoutes>
        <Route path="/products/import" element={<ProductsImport />} />
        <Route
          path="/products/icecat-import"
          element={<ProductsIcecatImport />}
        />
      </CustomRoutes>
      <Resource name="reviews" />

      <Resource
        name="support-requests"
        options={{ label: "Support Queries" }}
        {...supportRequests}
      />
      <Resource name="users" {...users} />

      <Resource
        name="external-suppliers"
        {...externalSuppliers}
        options={{ label: "External Suppliers" }}
      />

      {/* <Resource
        name="external-supplier-items"
        {...externalSuppliers}
        options={{ label: "External Suppliers" }}
      /> */}
    </Admin>
  );
}

export default App;
