import axios from "axios";
import { DataProvider as DataProviderInterface } from "ra-core";
import { Auth } from "aws-amplify";
import { HttpError } from "react-admin";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

axios.interceptors.request.use(
  async (config: any) => {
    const auth = await Auth.currentSession();
    const token = auth.getAccessToken();
    config.headers["Authorization"] = `Bearer ${token.getJwtToken()}`;
    return config;
  },
  (err) => {
    return Promise.reject(err);

    // console.log("gets here");

    // return Promise.reject(
    //   new HttpError(
    //     err.response.data.message,
    //     err.response.status,
    //     err.response.data.errors
    //   )
    // );
  }
);

export const network = axios;

const dataProvider: DataProviderInterface = {
  getList: async (resource: string, params: any) => {
    const {
      pagination: { page, perPage },
      sort: { field, order },
      filter,
      relation_type,
      relation_id,
    } = params;

    const {
      data: { data, meta },
    } = await axios.get(resource, {
      params: {
        page,
        perPage,
        sortField: field,
        sortOrder: order,
        relation_id,
        relation_type,
        ...filter,
      },
    });

    return {
      data: data,
      total: meta.total,
      pageInfo: {
        hasNextPage: meta.current_page < meta.last_page,
        hasPreviousPage: meta.current_page > 1,
      },
    };
  },
  getOne: async (resource: string, params: any) => {
    const {
      data: { data },
    } = await axios.get(`${resource}/${params.id}`, {
      params: {
        full: true,
      },
    });

    return { data };
  },
  getMany: async (resource: string, params: any) => {
    const { ids } = params;

    const {
      data: { data },
    } = await network.get(resource, {
      params: {
        ids,
        is_relation_query: 1,
      },
    });

    return {
      data: data,
      total: data.length,
    };
  },
  getManyReference: async (resource: string, params: any) => {
    const { target, id } = params;

    const {
      data: { data },
    } = await network.get(resource, {
      params: {
        relation_id: id,
        relation_type: target,
        is_relation_query: 1,
      },
    });

    return {
      data: data,
      total: data.length,
    };
  },
  create: async (resource: string, params: any) => {
    const {
      data: { data },
    } = await network.post(resource, params.data);
    return {
      data,
    };
  },
  update: async (resource: string, params: any) => {
    try {
      const {
        data: { data },
      } = await network.put(`${resource}/${params.id}`, params.data);

      return {
        data,
      };
    } catch (error: any) {
      return Promise.reject(
        new HttpError(
          error.response.data.message,
          error.response.status,
          error.response.data.errors
        )
      );
    }
  },
  updateMany: async (resource: string, params: any) => axios.get(resource),
  delete: async (resource: string, params: any) => {
    const { id } = params;
    const { data } = await network.delete(`${resource}/${id}`);
    return {
      data,
    };
  },
  deleteMany: async (resource: string, params: any) => network.delete(resource),
};

export default dataProvider;
