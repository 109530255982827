import {
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";

const fileTypes = [
  {
    id: "brand_logo",
    name: "Brand logo",
    requirements: [
      "Transparent background",
      "Must be less than 1mb",
      "Neither the width or length can be greater than 400px",
      "Recommended: 150x100 and less than 100kb",
    ],
  },
  {
    id: "product_image",
    name: "Product Image",
    requirements: [
      "White or transparent background",
      "Must be Square",
      "Must be less than 2mb",
      "Must be greater than 300x300",
      "Must be less than 1200x1200",
      "Recommended: 1000x1000 and less than 250kb",
    ],
  },
];
const FileCreate: React.FC<any> = (props) => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (values: any) => {
    let formData = new FormData();

    formData.append("file", values.image.rawFile);
    formData.append("name", values.name);

    create(`files/${values.type}`, formData, {
      onSuccess: ({ data }: any) => {
        redirect("show", "/files", data.id);
      },
      onFailure: ({
        response: {
          data: { message },
        },
      }: any) => {
        notify(message, "error");
      },
    });
  };

  return (
    <Create {...props}>
      <SimpleForm save={handleSubmit}>
        <ImageInput source="image" label="Image" accept="image/*">
          <ImageField source="image" />
        </ImageInput>
        <TextInput source="name" />
        <SelectInput source="type" choices={fileTypes} />
        <h5>Image Requirements:</h5>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type ? (
              <>
                {fileTypes
                  .find((ft) => ft.id === formData.type)
                  ?.requirements.map((req, index) => (
                    <li key={index}>{req}</li>
                  ))}
              </>
            ) : (
              <p>Please choose an image type to view requirements</p>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default FileCreate;
