import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import { TextField, Button } from "@mui/material";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Button } from "@material-ui/core";
import dataProvider from "../../utils/dataProvider";
import { useNotify } from "react-admin";

const CreateSupplierItemModalForm = ({ open, handleClose, record }: any) => {
  const notify = useNotify();

  const handleSubmit = async (values: any) => {
    // Replace 'resourceName' with the name of your resource
    console.log(values);
    dataProvider
      .create("external-supplier-items", {
        data: { ...values, supplier_id: record.id },
      })
      .then(() => {
        notify("Record created successfully");
        handleClose();
      })
      .catch((error) => {
        notify(error.message, { type: "error" });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "500px" } }}
    >
      <DialogTitle>Create New Item</DialogTitle>
      <DialogContent>
        {/* <form onSubmit={handleSubmit(saveIt)}>
          <TextField
            name="external_id"
            label="External Supplier Item ID"
            fullWidth
          />

          <Controller
            name="product_id"
            control={control}
            render={({ field }) => (
              <ReferenceInput
                label="Product"
                source="product_id"
                reference="products"
                {...field}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
          />
        </form> */}
        <Create>
          <SimpleForm
            resource="external-supplier-items"
            onSubmit={handleSubmit}
          >
            <TextInput
              source="external_id"
              label="Supplier Product ID"
              fullWidth
            />
            <ReferenceInput
              source="product_id"
              reference="products"
              label="Product"
              filterToQuery={(searchText: any) => ({ name: searchText })}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </SimpleForm>
        </Create>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSupplierItemModalForm;
