import { useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles: any = makeStyles({
  title: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 800,
  },
  value: {
    marginLeft: 10,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  row: {
    padding: 3,
    display: "flex",
    alignItems: "center",
    maxWidth: "600px",
    marginBottom: "10px",
  },
});

const ObjectKeyValueField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const classes = useStyles();

  return (
    <div>
      {Object.keys(record[source]).map((rk) => (
        <div className={classes.row}>
          <div className={classes.title}>{rk}</div>:
          <div className={classes.value}>{record[source][rk]}</div>
        </div>
      ))}
    </div>
  );
};

export default ObjectKeyValueField;
