import { MdOutlinePermMedia } from "react-icons/md";
import MediaList from "./List";
import MediaShow from "./Show";
import MediaCreate from "./Create";
import MediaEdit from "./Edit";

const mediaGallery = {
  icon: MdOutlinePermMedia,
  list: MediaList,
  show: MediaShow,
  create: MediaCreate,
  edit: MediaEdit,
};

export default mediaGallery;
