import React, { useState } from "react";
import {
  Datagrid,
  Show,
  TextField,
  TabbedShowLayout,
  ReferenceManyField,
  Tab,
  useShowController,
  DateField,
  ReferenceField,
  DeleteButton,
  TopToolbar,
} from "react-admin";
import CreateSupplierItemModalForm from "../../components/Forms/CreateSupplierItemModalForm";
import { Button } from "@mui/material";
import { useRefresh } from "react-admin";

// const ProductsListActions = () => {
//   // let navigate = useNavigate();

//   return (
//     <TopToolbar>
//       {/* <FilterButton /> */}
//       <CreateButton resource="external-supplier-items" />
//       {/* <ExportButton /> */}
//     </TopToolbar>
//   );
// };

const ESShow: React.FC<any> = (props) => {
  const refresh = useRefresh();
  const [modalOpen, setModalOpen] = useState(false);

  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="driver" />

          <DateField label="Created At" source="created_at" />
          <TextField source="products" />
          {/* <TextField source="stripe_id" label="Stripe User Id"/> */}
        </Tab>

        <Tab label="Product Items" path="product-items">
          <TopToolbar>
            <Button onClick={() => setModalOpen(true)}>Add Product</Button>
          </TopToolbar>
          <ReferenceManyField
            reference="external-supplier-items"
            target="external-supplier"
            // addLabel={false}
            label={false}
          >
            <Datagrid>
              <TextField source="id" />

              <ReferenceField
                source="product_id"
                reference="products"
                sortable={false}
                link="show"
              >
                <TextField source="name" label="Product" />
              </ReferenceField>

              <TextField
                source="external_id"
                label="Supplier Local Product ID"
              />
              <DeleteButton mutationMode="pessimistic" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>

      <CreateSupplierItemModalForm
        open={modalOpen}
        handleClose={() => {
          setModalOpen(false);
          refresh();
        }}
        record={record}
      />
    </Show>
  );
};

export default ESShow;
