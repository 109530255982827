import React from "react";
import { Datagrid, List, TextField, ShowButton } from "react-admin";

const ExternalSuppliersList: React.FC<any> = (props) => {
  return (
    <List {...props} sort={{ field: "name", order: "ASC" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TextField source="products" />

        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ExternalSuppliersList;
