import { Datagrid, List, TextField, BooleanField } from "react-admin";
import React from "react";

const UsersList: React.FC<any> = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="email" sortable={false} />
        <BooleanField source="active" sortable={false} />
        <TextField source="status" sortable={false} />
        <TextField source="id" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default UsersList;
