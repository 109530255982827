import * as React from "react";
import { Button } from "react-admin";
// import Button from "@material-ui/core/Button";

const PreviewButton: React.FC<any> = (props) => {
  const { resource, record } = props;
  const frontendUrl = process.env.REACT_APP_STOREFRONT_BASE_URL;

  const getUrl = () => {
    switch (resource) {
      case "manufacturers":
        return frontendUrl + "/manufacturers/" + record["id"];
      case "categories":
        return frontendUrl + "/products/" + record["full_slug"];
      case "industries":
        return frontendUrl + "/products/" + record["slug"];
      case "products":
        return frontendUrl + "/products/view/" + record["slug"];
      case "brands":
        return frontendUrl + "/brands/" + record["slug"];
      default:
        return "/";
    }
  };
  const showButton = () => {
    switch (resource) {
      case "manufacturers":
        return record["verified"];
      case "products":
        return record["verified"];
      default:
        return true;
    }
  };

  const openPreview = () => {
    let url = getUrl();
    (window! as any).open(url!, "_blank").focus();
  };

  return (
    <>
      {showButton() && (
        <Button
          // size="small"
          // variant="contained"
          color="primary"
          onClick={() => openPreview()}
          label="Preview"
        ></Button>
      )}
    </>
  );
};

export default PreviewButton;
