import {
  CreateButton,
  FilterButton,
  FilterContext,
  FilterForm,
  ListBase,
  Pagination,
  SearchInput,
  SortButton,
  Title,
  TopToolbar,
} from "react-admin";
import { Box } from "@material-ui/core";
import GridList from "./GridList";
import Aside from "./Aside";

export const productFilters = [<SearchInput source="q" alwaysOn />];

const ListActions = ({ resource }: any) => (
  <TopToolbar>
    <FilterButton />
    <SortButton fields={["created_at", "name"]} />
    <CreateButton resource={resource} />
    {/* <ExportButto /> */}
  </TopToolbar>
);

const MediaListView = ({ isSmall }: { isSmall: boolean }) => {
  // const { defaultTitle } = useListContext();
  return (
    <>
      <Title title="Gallery" />
      <FilterContext.Provider value={productFilters}>
        <ListActions isSmall={isSmall} />
        {isSmall && (
          <Box m={1}>
            <FilterForm />
          </Box>
        )}
      </FilterContext.Provider>
      <Box display="flex">
        <Aside />
        <Box width={isSmall ? "auto" : "calc(100% - 0em)"}>
          <GridList />
          <Pagination rowsPerPageOptions={[10, 20, 40]} />
        </Box>
      </Box>
    </>
  );
};

const MediaList = (props: any) => {
  return (
    <ListBase
      perPage={20}
      sort={{ field: "created_at", order: "ASC" }}
      {...props}
    >
      <MediaListView isSmall={false} />
    </ListBase>
  );
};

export default MediaList;
