import React from "react";
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  useShowController,
  TopToolbar,
  EditButton,
  RichTextField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  ReferenceField,
  BooleanField,
} from "react-admin";
import PreviewButton from "../../components/Buttons/PreviewButton";
import GalleryImageField from "../../components/Fields/GalleryImageField";
import ObjectKeyValueField from "../../components/Fields/ObjectKeyValueField";

const ShowProductActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <PreviewButton resource={resource} record={data} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const ProductShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} actions={<ShowProductActions />}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="brand.name" label="Brand" />
          <TextField source="make" />
          <TextField source="model" />
          <TextField source="short_description" />
          <RichTextField source="description" />
        </Tab>

        <Tab label="Stock, Pricing &amp; Availability">
          <TextField source="stock_level" />
          <TextField label="Guide Price (GBP)" source="guide_price" />
          <TextField
            label="Lowest Available Price (GBP)"
            source="lowest_price"
          />
          <TextField
            label="Fastest lead time (Days)"
            source="fastest_lead_time"
          />
          <BooleanField
            source="verified"
            label="Product is verified and shown on frontend"
          />
        </Tab>

        <Tab label="Images">
          {/* <ImageField source="thumbnail" title="thumbnail" /> */}
          <GalleryImageField source="thumbnail_id" title="Thumbnail" single />
          {/* <ArrayField source="images">
            <SingleFieldList>
              <ImageField source="path" />
            </SingleFieldList>
          </ArrayField> */}
          <GalleryImageField source="image_ids" title="Images" />
        </Tab>

        <Tab label="Categories">
          <ReferenceManyField
            reference="categories"
            target="product"
            addLabel={false}
          >
            <Datagrid hasBulkActions={false}>
              <TextField source="name" />
              <TextField source="slug" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Items">
          <ReferenceManyField
            reference="product-items"
            target="product"
            addLabel={false}
          >
            <Datagrid hasBulkActions={false}>
              <TextField source="status" />
              <TextField source="price" label="Price (GBP)" />
              <TextField source="delivery_price" label="Delivery Price (GBP)" />
              <TextField source="est_lead_time" label="Est lead time (Days)" />
              <ReferenceField
                label="Manufacturer"
                source="manufacturer_id"
                reference="manufacturers"
                sortable={false}
                link="show"
              >
                <TextField source="name" label="Manufacturer" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Category Details">
          <ObjectKeyValueField source="category_details" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ProductShow;
