import React from "react";
import {Datagrid, List, TextField, ShowButton, TextInput, ReferenceField} from "react-admin";

const ordersFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
];

const OrdersList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "order_number", order: "ASC" }}
      filters={ordersFilters}
    >
      <Datagrid>
        <ReferenceField
            source="user_id"
            reference="users"
            sortable={false}
            link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="order_number" />
        <TextField source="amount" />
        <TextField source="status" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default OrdersList;
