import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddButton from "./AddButton";
import GalleryImageField from "../../Fields/GalleryImageField";
import { useInput } from "react-admin";
import { TiTrash } from "react-icons/ti";
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useWatch } from "react-hook-form";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  addImage: {
    height: 100,
    width: 100,
    margin: 10,
    border: "2px dashed lightgray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    color: "gray",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: 5,
    "&:hover": {
      color: "black",
      border: "2px dashed black",
    },
  },
});

const GalleryImageInput = (props: any) => {
  const classes = useStyles();
  const [changing, setChanging] = useState(false);

  const {
    field: { onChange },
    fieldState: { isTouched, error },
  } = useInput(props);

  const source = useWatch({
    name: props.source,
    defaultValue: props.single ? null : [],
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      var newItems = source;
      const oldIndex = newItems.indexOf(active.id);
      const newIndex = newItems.indexOf(over.id);

      onChange({ target: { value: arrayMove(newItems, oldIndex, newIndex) } });
    }
  };

  const canAdd = useMemo(
    () => (props.single && !source) || !props.single,
    [source, props.single]
  );

  return (
    <div>
      <p>{props.label}</p>

      <div className={classes.root}>
        {!props.single && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={source}
              strategy={verticalListSortingStrategy}
            >
              {source.map((id: number) => (
                <ImageHolder
                  key={id}
                  onRemove={() => {
                    const newItems = [...source].filter((ff) => ff !== id);
                    onChange({ target: { value: newItems } });
                  }}
                >
                  <SortableItem id={id}>
                    <GalleryImageField
                      source={props.source}
                      single
                      maxSize={100}
                      overWriteValue={id}
                    />
                  </SortableItem>
                </ImageHolder>
              ))}
            </SortableContext>
          </DndContext>
        )}

        {props.single && (
          <div>
            {!!source && !changing && (
              <ImageHolder
                onRemove={() => {
                  onChange({ target: { value: null } });
                }}
              >
                <GalleryImageField
                  source={props.source}
                  single={!!props.single}
                  maxSize={100}
                  overWriteValue={source}
                />
              </ImageHolder>
            )}
          </div>
        )}

        {canAdd && (
          <AddButton
            onChange={(v: any) => {
              setChanging(true);
              if (props.single) {
                onChange({ target: { value: v } });
              } else {
                onChange({ target: { value: [...source, v] } });
              }

              console.log(source);
              setChanging(false);
            }}
            uploadType={props.uploadType}
          />
        )}
        {/* <ReferenceInput key={version} {...props} reference="files">
        <SelectInput optionText="name" />
        </ReferenceInput>
        
        <CreateButton onChange={handleChange} />
      {!!values[props.source] && <PreviewButton id={values[props.source]} />} */}
      </div>

      {!!(isTouched && error) && (
        <>
          {error.type === "required" && (
            <div
              style={{
                color: "red",
                fontWeight: "medium",
                fontFamily: "sans-serif",
                fontSize: 12,
              }}
            >
              {props.label} is required
            </div>
          )}
        </>
      )}
    </div>
  );
};

const useHolderStyles = makeStyles({
  root: {
    position: "relative",
    marginRight: 20,
  },
  icon: {
    backgroundColor: "white",
    borderRadius: "100%",
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -10,
    right: -10,
    boxShadow: "0px 0px 3px #888888",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "red",
      color: "white",
    },
  },
});

const ImageHolder = ({ onRemove = () => {}, children }: any) => {
  const styles = useHolderStyles();
  return (
    <div className={styles.root}>
      <div
        className={styles.icon}
        onClick={() => {
          onRemove();
        }}
      >
        <TiTrash size={20} />
      </div>
      {children}
    </div>
  );
};

export function SortableItem({ id, children }: any) {
  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    args.isSorting || args.wasDragging
      ? defaultAnimateLayoutChanges(args)
      : true;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      animateLayoutChanges,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
}

export default GalleryImageInput;
