import { MdSupport } from "react-icons/md";
import SupportRequestShow from "./Show";
import SupportRequestsList from "./List";

const supportRequestsResource = {
  icon: MdSupport,
  show: SupportRequestShow,
  list: SupportRequestsList,
};
export default supportRequestsResource;
