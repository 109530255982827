import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    required,
    SaveButton,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    FormTab,
    TextInput,
    Toolbar,
} from "react-admin";
import IndustryCategorySelectInput from "../../components/Inputs/IndustryCategorySelectInput";

const CategoryEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton/>
    </Toolbar>
);

export const CategoryEdit: React.FC<any> = (props) => {
    return (
        <Edit {...props} undoable={false}>
            <TabbedForm toolbar={<CategoryEditToolbar/>}>
                <FormTab label="Category Details">
                    <TextInput source="name" validate={required()}/>
                    <TextInput source="description" multiline validate={required()}/>
                    <IndustryCategorySelectInput/>
                </FormTab>

                <FormTab label="Product Detail Fields">
                    <ArrayInput source="product_detail_fields">
                        <SimpleFormIterator>
                            <TextInput label="Field Name" source="label" />
                            <SelectInput
                                label="Field Type"
                                source="type"
                                choices={[
                                    {
                                        id: "text",
                                        name: "Text",
                                    },
                                    {
                                        id: "textarea",
                                        name: "Text Area",
                                    },
                                    {
                                        id: "file",
                                        name: "File",
                                    },
                                    {
                                        id: "files",
                                        name: "Files",
                                    },
                                    {
                                        id: "checkbox",
                                        name: "Checkbox",
                                    },
                                    {
                                        id: "select",
                                        name: "Select",
                                    },
                                    {
                                        id: "multiselect",
                                        name: "Multiselect",
                                    },
                                ]}
                            />
                            <BooleanInput source="required" label="Field Required" />

                            <FormDataConsumer>
                                {({ scopedFormData, getSource, ...rest }: any) =>
                                    scopedFormData?.type && (
                                        <>
                                            {["select", "multiselect"].includes(
                                                scopedFormData.type
                                            ) && (
                                                <ArrayInput
                                                    source={getSource("options")}
                                                    label="Select options"
                                                >
                                                    <SimpleFormIterator>
                                                        <TextInput source="" label="Field Option" />
                                                    </SimpleFormIterator>
                                                </ArrayInput>
                                            )}
                                        </>
                                    )
                                }
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default CategoryEdit;
