import React from "react";
import {
  Show,
  BooleanField,
  TextField,
  UrlField,
  useShowController,
  EditButton,
  TopToolbar,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  ShowButton,
} from "react-admin";
import PreviewButton from "../../components/Buttons/PreviewButton";
import GalleryImageField from "../../components/Fields/GalleryImageField";

const ShowBrandActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <PreviewButton resource={resource} record={data} />
      {/* <DeleteButton basePath={basePath} record={data} undoable /> */}
      <EditButton record={data} />
    </TopToolbar>
  );
};
const BrandShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show actions={<ShowBrandActions />}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <UrlField source="url" />
          <GalleryImageField source="logo_id" title="Logo" single />
        </Tab>

        <Tab label="Categories">
          <ReferenceManyField
            reference="categories"
            target="brand"
            label={undefined}
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" />
              <TextField source="slug" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Industries">
          <ReferenceManyField
            reference="industries"
            target="brand"
            label={false}
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" />
              <TextField source="slug" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="products" path="brand-products">
          <ReferenceManyField reference="products" target="brand" label={false}>
            <Datagrid bulkActionButtons={false}>
              <GalleryImageField
                source="thumbnail_id"
                single
                maxSize={50}
                sortable={false}
              />
              <TextField source="name" />
              <TextField source="stock_level" />
              <TextField source="make" />
              <TextField source="model" />
              <BooleanField source="verified" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default BrandShow;
