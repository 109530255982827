import React from "react";
import {
  Show,
  ArrayField,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  useShowController,
  ReferenceManyField,
  ShowButton,
  TopToolbar,
  EditButton,
} from "react-admin";
import PreviewButton from "../../components/Buttons/PreviewButton";

const ShowIndustryActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <PreviewButton resource={resource} record={data} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const IndustryShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} actions={<ShowIndustryActions />}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <TextField source="sort_order" />
          <TextField source="slug" />
          <TextField source="description" />
        </Tab>
        <Tab label="Registration Settings">
          <ArrayField source="registration_settings">
            <Datagrid>
              <TextField source="label" />
              <TextField source="type" />
              <BooleanField source="required" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="categories" path="industry-categories">
          <ReferenceManyField
            reference="categories"
            target="industry"
            addLabel={false}
          >
            <Datagrid hasBulkActions={false}>
              <TextField source="name" />
              <TextField source="slug" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default IndustryShow;
