import { useCallback, useEffect, useState } from "react";
import { BooleanInput, SelectInput, TextInput } from "react-admin";
import { network } from "../../utils/dataProvider";

const ProductCategoryDataInput = ({ productId }: any) => {
  const [questions, setQuestions] = useState([]);

  const getQuestions = useCallback(async () => {
    try {
      const { data } = await network.get(`products/${productId}/edit`);

      const reducedAndFiltered = data
        .reduce((cr: any, nx: any) => [...cr, ...nx], [])
        .filter(
          (value: any, index: any, self: any) =>
            self.map((x: any) => x.id).indexOf(value.id) === index
        );

      setQuestions(reducedAndFiltered);
    } catch (error) {
      setQuestions([]);
    }
  }, [productId]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const getFieldForQuestion = (question: any) => {
    const sharedProps = {
      source: `category_details.${question.id}`,
      label: question.label,
    };
    switch (question.type) {
      case "text":
        return <TextInput {...sharedProps} />;
      case "select":
      case "multiselect":
        return (
          <SelectInput
            {...sharedProps}
            choices={question.options.map((opt: string) => ({
              id: opt.toString(),
              name: opt.toString(),
            }))}
          />
        );
      case "checkbox":
        return <BooleanInput {...sharedProps} />;

      default:
        return null;
    }
  };
  return (
    <>
      {questions.map((question: any) => (
        <div key={question.id}>{getFieldForQuestion(question)}</div>
      ))}
    </>
  );
};
export default ProductCategoryDataInput;
