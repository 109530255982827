import React from "react";
import {
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import GalleryImageInput from "../../components/Inputs/GalleryImageInput";

const BrandEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const BrandsEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props} undoable={false} mutationMode="pessimistic">
      <SimpleForm toolbar={<BrandEditToolbar />}>
        <TextInput source="name" validate={required()} />
        <TextInput source="url" />
        <GalleryImageInput source="logo_id" single label="Logo" uploadType="brand_logo"/>
      </SimpleForm>
    </Edit>
  );
};

export default BrandsEdit;
