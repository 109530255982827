import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  TextInput,
  DateField,
} from "react-admin";

const usersFilters = [<TextInput label="Search" source="search" alwaysOn />];

const UsersList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      sort={{ field: "name", order: "ASC" }}
      filters={usersFilters}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <DateField label="User Since" source="created_at" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default UsersList;
