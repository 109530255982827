import OrdersList from "./List";
import OrdersShow from "./Show";
import { GrTask } from "react-icons/gr";

const ordersResource = {
  icon: GrTask,
  show: OrdersShow,
  list: OrdersList,
};

export default ordersResource;
