import React from "react";
import {
  Show,
  ImageField,
  TextField,
  SimpleShowLayout,
  useShowController,
  EditButton,
  TopToolbar,
  DeleteButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useImageFieldStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const ShowFileActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <DeleteButton basePath={basePath} record={data} undoable />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};
const FileShow: React.FC<any> = (props) => {
  const imageFieldClasses = useImageFieldStyles();
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} actions={<ShowFileActions />}>
      <SimpleShowLayout>
        <ImageField source="url" label="Preview" classes={imageFieldClasses} />
        <TextField source="name" />
        <TextField source="extension" />
        <TextField source="type" />
        <TextField source="path" />
        <TextField source="size" label="File Size (MB)" />
        <TextField source="width" label="Width (PX)" />
        <TextField source="height" label="Height (PX)" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FileShow;
