import { GrCatalog } from "react-icons/gr";
import ProductShow from "./Show";
import ProductsList from "./List";
import ProductsCreate from "./Create";
import ProductsEdit from "./Edit";

const productsResource = {
  show: ProductShow,
  list: ProductsList,
  create: ProductsCreate,
  edit: ProductsEdit,
  icon: GrCatalog,
};

export default productsResource;
