import { Create, required, SimpleForm, TextInput } from "react-admin";

const ProductCreate: React.FC<any> = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export default ProductCreate;