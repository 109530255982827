import React from "react";
import {
  Show,
  DateField,
  TextField,
  SimpleShowLayout,
  useShowController,
  TopToolbar,
  useUpdate,
  useRecordContext,
} from "react-admin";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const SupportRequestShowActions = ({ basePath, data, resource }: any) => {
  const record = useRecordContext();

  const [update] = useUpdate();

  const setStatus = (value: string) => {
    update("support-requests", {
      id: record.id,
      data: { status: value },
      previousData: record,
    });
  };
  return (
    <TopToolbar>
      <FormControl variant="standard">
        <InputLabel id="set-status-label">Status</InputLabel>
        <Select
          labelId="set-status-label"
          id="set-status-select"
          value={record.status}
          label="Status"
          variant="filled"
          onChange={(event) => setStatus(event.target.value as string)}
        >
          <MenuItem value="submitted">submitted</MenuItem>
          <MenuItem value="invalid">invalid</MenuItem>
          <MenuItem value="investigation">investigation</MenuItem>
          <MenuItem value="resolved">resolved</MenuItem>
        </Select>
      </FormControl>
    </TopToolbar>
  );
};

const SupportRequestShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} actions={<SupportRequestShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="subject" />
        <TextField source="email_address" />
        <TextField source="user_name" />
        <TextField source="message" />
        <DateField source="created_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default SupportRequestShow;
