import * as React from "react";
import { ImageList, GridListTile, GridListTileBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { useListContext, DatagridProps, useCreatePath } from "react-admin";
import { Link } from "react-router-dom";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
}));

const getColsForWidth = (width: Breakpoint) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 3;
  if (width === "lg") return 5;
  return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = (props: GridProps & { nbItems?: number }) => {
  const { width, nbItems = 20 } = props;
  const classes = useStyles();
  return (
    <ImageList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {" "}
      {times(nbItems, (key) => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </ImageList>
  );
};

const LoadedGridList = (props: GridProps) => {
  const { width } = props;
  const { data, isLoading } = useListContext();
  const classes = useStyles();
  const createPath = useCreatePath();

  if (isLoading || !data) return null;

  return (
    <ImageList cols={getColsForWidth(width)} className={classes.gridList}>
      {data.map((record: any) => (
        <GridListTile
          component={Link}
          key={record.id}
          to={createPath({ resource: "files", type: "show", id: record.id })}
        >
          <img src={record.url} alt="" />
          <GridListTileBar
            className={classes.tileBar}
            title={record.name}
            subtitle={
              <span>
                {record.width ?? 150}x{record.height ?? 150}, {record.extension}
              </span>
            }
          />
        </GridListTile>
      ))}
    </ImageList>
  );
};

interface GridProps extends Omit<DatagridProps, "width">, WithWidth {}

const GridList = (props: WithWidth) => {
  const { width } = props;
  const { isLoading } = useListContext();
  return !isLoading ? (
    <LoadedGridList width={width} />
  ) : (
    <LoadingGridList width={width} />
  );
};

export default withWidth()(GridList);
