import React from "react";
import {
  Datagrid,
  Show,
  TextField,
  TabbedShowLayout,
  ReferenceManyField,
  Tab,
  useShowController,
  DateField,
} from "react-admin";

const UserShow: React.FC<any> = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="details">
          <TextField source="name" />
          <TextField source="email" />
          <DateField label="User Since" source="created_at" />
          <TextField source="phone" />
          <TextField source="stripe_id" label="Stripe User Id"/>
        </Tab>

        <Tab label="orders" path="orders">
          <ReferenceManyField
              reference="orders"
              target="user"
              addLabel={false}
          >
            <Datagrid hasBulkActions={false}>
              <TextField source="id" />
              <TextField source="order_number" />
              <TextField source="amount" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="reviews" path="reviews">
          <TextField source="id" />
        </Tab>
        <Tab label="Eol Items" path="eol-items">
          <ReferenceManyField
              reference="eol-items"
              target="user"
              addLabel={false}
          >
            <Datagrid hasBulkActions={false}>
              <TextField source="serial_number" />
              <TextField source="price" />
              <TextField source="status" />
              <TextField source="product_details" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
