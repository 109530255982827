import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  List,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";

const manufacturerFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
  <BooleanInput
    label="Only show pending approval"
    source="hideVerified"
    alwaysOn
  />,
];

const ManufacturersList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      filters={manufacturerFilters}
      bulkActionButtons={false}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="company_number" />
        <BooleanField source="verified" />
        <TextField source="rating" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ManufacturersList;
