import React from "react";
import { Datagrid, List, ShowButton, TextField, TextInput } from "react-admin";

const industriesFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
];

const IndustriesList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "name", order: "ASC" }}
      filters={industriesFilters}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="slug" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default IndustriesList;
