import React from "react";
import {
  Datagrid,
  List,
  TextField,
  UrlField,
  ShowButton,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import GalleryImageField from "../../components/Fields/GalleryImageField";

const useImageFieldStyles = makeStyles((theme) => ({
  image: {
    width: "auto",
    height: 40,
  },
}));

const brandsFilters = [<TextInput label="Search" source="search" alwaysOn />];

const BrandsList: React.FC<any> = (props) => {
  const imageFieldClasses = useImageFieldStyles();

  return (
    <List
      {...props}
      sort={{ field: "name", order: "ASC" }}
      filters={brandsFilters}
    >
      <Datagrid bulkActionButtons={<></>}>
        <TextField source="name" />
        <UrlField source="url" />
        <GalleryImageField
          source="logo_id"
          classes={imageFieldClasses}
          single
          maxSize={60}
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default BrandsList;
