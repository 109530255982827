import UsersList from "./List";
import UsersShow from "./Show";
import { MdPeopleOutline } from "react-icons/md";

const usersResource = {
  icon: MdPeopleOutline,
  show: UsersShow,
  list: UsersList,
};

export default usersResource;
